export const DishModalHeroImage = ({item, currentImage, setCurrentImage, pathToImageDir}) => {
    return (
        <div className='sm:max-h-full'>
            <img className="lg:h-full xl:max-h-[650px] rounded mx-auto lg:mx-0" src={require(`${pathToImageDir + currentImage}`)} alt={item.dish}/>
            <div className="lg:hidden flex justify-center h-32">
                {item.images.map(imgPath => 
                    <button className='max-h-20' onClick={() => setCurrentImage(imgPath)}>
                        <img className="w-24 min-h-24 my-3" src={require(`${pathToImageDir + imgPath}`)} alt={item.dish}/>
                    </button>)
                }
            </div>
        </div>
    )
}
