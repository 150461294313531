import { NavLink } from "react-router-dom"
import { Navigation } from "./Navigation"

export const Header = ({navBar}) =>
    <header className="sticky top-0 z-50 h-[124px]">
        <section className="grid grid-rows-1 grid-cols-3 sm:grid-cols-5 bg-[#443266] items-stretch w-full h-full">
            <section className="bg-inherit text-center my-auto sm:col-span-2 sm:m-auto">
                <NavLink to="/">
                    <img src={require("./icons/OIE-front-logo.jpg")} alt="Oie Truly Thai Logo"
                         className="h-[120px] rounded-full sm:ml-0 ml-5"/>
                </NavLink>
            </section>
            <section />
            <section className="bg-inherit text-right sm:text-center my-auto sm:flex
                sm:place-content-center sm:my-auto sm:col-span-2">
                {navBar.map(nav => <Navigation name={nav.name} link={nav.link}/>)}
            </section>
        </section>
    </header>