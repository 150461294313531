import { Dish } from './Dish';

export const MenuSection = ({section, dishes}) => {
    return (
        <section>
            <hr className='my-3' />
            <h2 className="text-3xl font-bold my-3">{section}</h2>
            <section>
                {dishes.map(dish => 
                    <ul>
                         <li>
                            <Dish item={dish}/>
                        </li>
                    </ul>
                )}
            </section>
        </section>
    )
}