import { AboutDescriptionLeft } from "./AboutDescriptionLeft.jsx"
import { AboutDescriptionRight } from "./AboutDescriptionRight.jsx"
import descriptions from "./data/aboutus.json"

export const About = () =>
    <section>
        <h3 className="my-4 font-bold text-4xl mb-10 flex place-content-center">About OIE</h3>
        <section className="max-h-[2500px] mt-6 px-2 lg:px-32 text-center mx-auto"> {
            descriptions.map((des, index) =>
            <section>
                <div className="py-2" />
                    {index % 2 === 0 
                    ? <AboutDescriptionLeft description={des}/>
                    : <AboutDescriptionRight description={des} />}
                <div className="py-2" />
            </section>
            )
        } </section> 
    </section>