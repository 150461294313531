
export const Home = () => {

    const sharedImageStyling = "w-full h-full hover:opacity-25"
    const sharedTextStyling = "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-bold"
    const gridImages = [
        {name: "Chicken Krapow", imageStyle: `${sharedImageStyling}`, textStyle:`${sharedTextStyling}`, path: "krapow_sq"},
        {name: "Pad Thai", imageStyle: `${sharedImageStyling}`, textStyle:`${sharedTextStyling}`, path: "pad_thai"},
        {name: "Crispy Noodle Salad", imageStyle: `${sharedImageStyling}`, textStyle:`${sharedTextStyling}`, path: "crispy_noodle_salad_veg_sq"},
        {name: "Chicken Massaman", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "massaman"},
        {name: "Vegetarian Red Curry", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "red_curry_sq"},
        {name: "Beef Panang Curry", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "panang_beef_sq"},
        {name: "Tom Yum Soup", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "tom_yum_veg"},
        {name: "Spicy Prawn Salad", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "spicy_prawn_salad_sq"},
        {name: "Prawn Crackers", imageStyle: `hidden sm:block ${sharedImageStyling}`, textStyle:`hidden sm:block ${sharedTextStyling}`, path: "prawn_crackers_sq"}
    ]

return (
<section className="text-center">
    <img className="align-center max-w-screen my-[-100px]" src={require("./otherImages/HERO.jpg")} alt="Fresh Thai Ingredients"/>
    <section className="text-center mx-5 mb-5 mt-[125px] text-xl lg:text-3xl">
        <h3 className="font-bold my-3">Fresh & delicious Thai cuisine lovingly prepared by our head chef Oie!</h3>
        <p className="text-xl">Just check out some of our reviews!</p>
    </section>
    <section className="lg:mb-20 lg:mt-10 lg:grid lg:grid-cols-4 lg:grid-rows-1 md:w-full w-5/6 text-xl mx-auto">
        <section className="hidden lg:block"></section>
        <section className="text-center mx-auto lg:mr-5">
            <p className="text-[#443266]">★★★★★</p>
            <p className="italic sm:w-[600px] lg:w-full sm:mx-auto">"OIE Thai is definitely worth checking out, it's a 'one off' not a chain or a franchise and
                you can immediately taste the difference..."</p>
            <p className="mr-2">CDJDorset_UK, TripAdvisor</p>
            <a target="_blank" rel='noreferrer' className="mr-2 text-[#443266] underline" href="https://www.tripadvisor.com/ShowUserReviews-g186338-d14932314-r852809747-Oie_Truly_Thai-London_England.html">
                    Click here for the full review!</a>
        </section>
        <section className="text-center mt-5 lg:mt-0 lg:ml-5">
            <p className="text-[#443266]">★★★★★</p>
            <p className="italic sm:w-[600px] sm:mx-auto lg:w-full">"It has been my favourite place for lunch since they re-open. As they said on the wall,
                the food is authentic and delicious..."</p>
            <p className="ml-2">FP Wong, Google Reviews</p>
            <a target="_blank" rel='noreferrer' className="ml-2 text-[#443266] underline" href="https://www.google.com/maps/contrib/100942953985834660428/place/ChIJh0iIelIDdkgRklq727nWqqg/@51.5117139,-10.629668,5z/data=!4m6!1m5!8m4!1e1!2s100942953985834660428!3m1!1e1?hl=en-US">
                Click here for the full review!</a>
        </section>
        <section className="hidden lg:block" />
    </section>
    <section className="max-h-full mb-[-75px] sm:mb-0">
        <section className="text-xl text-center my-5 px-5 lg:px-48">
            <p>We take pride in our ability to deliver great looking food true to the flavours of Thailand!
            Have a look at a small selection of our dishes below!</p>
            <br />
            <p>While we maintain the authentic Thai taste, we occasionally have to substitute Thai vegetables with locally sourced ones.</p>
        </section>
        <section className="grid grid-rows-3 grid-cols-1 sm:grid-cols-3 mx-auto w-5/6 place-items-center items-stretch gap-5 lg:gap-2">
            {gridImages.map(image => 
                <div className="relative items-center justify-center w-full h-full text-transparent text-center hover:text-black">
                    <img src={require(`./dishImages/${image.path}.jpg`)} alt={image.name} className={image.imageStyle} />
                    <p className={image.textStyle}>{image.name}</p>
                </div>
            )}
        </section>
    </section>
    <section className="place-self-center text-center text-xl px-5 sm:mt-3 lg:my-10 lg:px-48">
        <h4 className="text-2xl font-bold">Give us a try!</h4>
        <p>Whether you're a fan of Thai cuisine or simply looking to try something new and delicious,
        OIE Truly Thai is the perfect place to indulge your taste buds. Visit us today and experience
        the taste of authentic Thai cuisine like never before!</p>
    </section>
</section>
)}
