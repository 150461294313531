import { NavLink } from "react-router-dom"

export const Footer = ({navBar}) => 
        <footer className="inset-x-0 bottom-0 w-screen h-64 mt-5">
            <div className="py-4"/>
            <section className="grid grid-rows-1 grid-cols-2 sm:grid-cols-3 bg-[#4c4c4c] h-64 text-white place-items-center">
                <section className="hidden sm:block sm:w-1/3">
                    <ul>
                        {navBar.map(nav => <li className="hover:text-[#cc60f3]/60 active:text-[#cc60f3]/30"><NavLink to={nav.link}>{nav.name}</NavLink></li>)}
                    </ul>
                </section>
                <section>
                    <ul className="list-image-[url(../images/icons/location_icon.png)]/100 ml-2">
                        <li>
                            <a href="https://www.google.co.uk/maps/place/145+Fenchurch+St,+London+EC3M+6BL" rel="noreferrer" target="_blank">
                                145 Fenchurch Street, London, EC3M 6BL,<br/>Opening Times: 11:00 - 14:30
                            </a>
                        </li>
                    </ul>
                </section>
                <section>
                    <ul className="">
                        <li className="my-5"><a target="_blank" rel="noreferrer" href="https://www.instagram.com/oietrulythai/">
                            <img src={require("./icons/instagram_icon.png")} className="h-5 w-5" alt="Oie Truly Thai Instagram" />
                        </a></li>
                    </ul>
                </section>
            </section>
        </footer>