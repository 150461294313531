export const AboutDescriptionLeft = ({description}) =>
    <section className="grid md:grid-cols-2 grid-cols-1 md:max-w-[1000px] mx-auto">
        <section className="w-full h-full md:block hidden">
            <section>
                <img className="w-[300px] mx-auto rounded-m" src={require(`${description.imagePath}`)} alt="Oie preparing food"/>
            </section>
        </section>
        <section>
            <section className="w-[350px] mx-auto">
                <h3 className="text-3xl font-bold">{description.title}</h3>
                <hr />
                <div className="py-2" />
                <p className="text-lg">{description.text}</p>
            </section>
        </section>
        <section className="w-full h-full mx-auto md:hidden block">
            <div className="py-2" />
            <section className="mx-auto my-auto mt-2">
                <img className="max-h-[300px] rounded-m mx-auto" src={require(`${description.imagePath}`)} alt="Oie preparing food"/>
            </section>
        </section>
    </section>