import Modal from 'react-modal';
import { useState } from 'react';
import { DishModalDetail } from './DishModalDetail.jsx';
import { DishModalHeroImage } from './DishModalHeroImage.jsx';

export const DishModal = ({showModal, closeModal, item}) => {
    // Modal.setAppElement("#DishModal");
    let subtitle;

    const [currentImage, setCurrentImage] = useState(`${item.images[0]}`)
    const pathToImageDir = "./images/"

    const customStyles = {
        content: {
          top: '55%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '75%',
          height: '75%',
          "z-index": 100,
          "border-radius": '15px'
        }
      };

      const afterOpenModal = () => {subtitle.style.color = '#f00'};
      const closeAndResetModal = (img) => {
        setCurrentImage(img)
        closeModal()
      }

return (
    <Modal style={customStyles}
      isOpen={showModal}
      afterOpenModal={afterOpenModal}
      onRequestClose={() => closeAndResetModal(item.images[0])} 
      contentLabel="DishModal">
        <div className="h-full">
          <div className="sm:h-12 sm:w-full inline-flex mb-5 lg:mb-3">
            <h3 className="text-3xl sm:text-4xl font-bold mx-auto">{item.dish}</h3>
            <button className="w-5 h-5" onClick={() => closeAndResetModal(item.images[0])}>
              <span className='text-2xl'>X</span>
            </button>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 min-h-inherit">
            <DishModalHeroImage item={item} currentImage={currentImage} 
              setCurrentImage={setCurrentImage} 
              pathToImageDir={pathToImageDir}/>
            <DishModalDetail item={item} setCurrentImage={setCurrentImage} pathToImageDir={pathToImageDir}/>
          </div>
        </div>
    </Modal>
)}
