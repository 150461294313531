import { getSpice, getTags } from "./utils.js"
// import { DishModal } from "./DishModal.jsx"
// import { useState } from 'react';

export const Dish = ({item}) => {
    // const [showModal, setShowModal] = useState(false);
    // const closeModal = () => setShowModal(false);
    // const openModal = setShowModal(true);
    const pathToImageDir = "./dishImages/"
    
return (
    <div className="my-1 border border-[#4c4c4c]/50 h-40 sm:h-56 rounded-md lg:w-2/3 w-5/6
        inline-flex sm:hover:scale-105 shadow-black cursor-pointer sm:ease-in-out
        sm:duration-200 sm:mx-0 text-left relative">
        <div className="grid grid-rows-5 w-2/3 md:w-3/4 px-3">
            <div className="row-span-1 h-full w-full relative">
                <h3 className="md:text-3xl text-2xl font-bold h-full">{item.dish}</h3>
            </div>
            <div className="row-span-3 w-full overflow-hidden line-clamp-6">
                <p className="hidden sm:block text-lg h-full">{item.description}</p>
            </div>
            <div className="row-span-1 inline-flex">
                <div className="w-[100px] my-auto">{getSpice(item.heatLevel)}</div>
                <div className="w-[100px] my-auto">
                    {getTags(item).map(tag => <img className='inline-flex w-4 mx-1 sm:mx-2' src={require(`${tag}`)} alt={tag}/>)}
                </div>
            </div>
        </div>
        <div className="w-40 sm:w-56 h-full absolute right-0">
            <img className="w-full h-full float-right rounded-r-md" src={require(`${pathToImageDir + item.images[0]}`)} alt={item.dish}/>
        </div>
    </div>
)}