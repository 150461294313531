export const AboutDescriptionRight = ({description}) => 
    <section className="grid md:grid-cols-2 grid-cols-1 md:max-w-[1000px] mx-auto">
        <section>
            <section className="w-[350px] mx-auto">
                <h3 className="text-3xl font-bold">{description.title}</h3>
                <hr />
                <div className="py-2"/>
                <p className="text-lg">{description.text}</p>
            </section>
        </section>
        <section className="w-full h-full mx-auto">
            <div className="py-2 md:py-0" />
            <section className="mx-auto my-auto mt-2">
                <img className="max-h-[300px] rounded-m mx-auto md:block hidden" src={require(`${description.imagePath}`)} alt="Oie preparing food"/>
                <img className="w-[300px] mx-auto rounded-m md:hidden block" src={require(`${description.imagePath}`)} alt="Oie preparing food"/>
            </section>
        </section>
    </section>