import { NavLink } from "react-router-dom"

export const Navigation = ({name, link}) => {
    return (
        <NavLink to={link}>
            <div className="max-h-inherit max-w-32 mr-10 lg:sm-0 my-2">
                <h3 className="text-white  hover:text-[#cc60f3]/60 active:text-[#cc60f3]/20 text-xl md:text-2xl font-semibold">{name}</h3>
            </div>
        </NavLink>
    )
}