export const NotFound = () => {
    const goHome = (event) => {
        event.preventDefault();
        window.location = "/"
    }

    return (
        <section className="h-[500px] text-center my-24">
            <h2 className="text-2xl font-style-bold">404 - Page Not Found</h2>
            <br />
            <h3 className="text-xl font-style-bold">Sorry... We can't find the page you're looking for...</h3>
            <br />
            <button className="w-2/3 sm:w-1/5 place-self-center rounded-full bg-[#7a5eb0] hover:bg-[#9078bd]/80 active:bg-[#a692ca]/60 py-6 px-8 text-white"
                onClick={goHome}><strong>Go to Home</strong></button>
        </section>
    )
}