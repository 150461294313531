import {getTags, listOptions} from "./utils.js"

export const DishModalDetail = ({item, setCurrentImage, pathToImageDir}) => {
    return (
        <div className="h-full">
            <div className="sm:px-2 text-md sm:text-2xl sm:max-h-[400px] lg:h-fit">
                {/* <h3 className="text-3xl font-bold mb-2">Description</h3> */}
                <p>{item.description}</p>
            </div>
            <div className="sm:ml-4 text-md sm:text-2xl lg:mt-3 lg:my-auto">
                    {listOptions(item.options).map(opt => 
                        <div className="w-full inline-flex h-14">
                            <div className="lg:min-w-2/5 place-content-left my-auto">
                                <h3 className="font-semibold">{opt.name}</h3>
                            </div>
                            <div className="lg:h-14 py-2 inline-flex place-content-left mr-1">
                                {getTags(opt).map(tag => <img className='w-5 mx-1 sm:mx-2 py-2' src={require(`${tag}`)} alt={tag}/>)}
                            </div>
                            <div className="w-fit py-2 absolute right-5 sm:right-20 mt-2 sm:mt-0">
                                <p className="w-fit">£{opt.price.toFixed(2)}</p>
                            </div>
                        </div>
                    )}
            </div>
            <div className="hidden lg:block lg:min-h-1/5 absolute bottom-[12px]">
                {item.images.map(imgPath => 
                    <button onClick={() => setCurrentImage(imgPath)}>
                        <img className="w-24 mx-5 my-3" src={require(`${pathToImageDir + imgPath}`)} alt={item.dish}/>
                    </button>
                )}
            </div>
        </div>
    )
}