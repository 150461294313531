export const ContactUs = () => 
<section class="min-h-screen flex place-content-center">
    <form class="mt-4 w-full sm:w-2/5 px-4">
        <label for="name" class="hidden">Name:</label>
        <label for="email" class="hidden">Email:</label><br />
        <input id="name" class="border border-[#4c4c4c] rounded-md pl-1 h-10 w-1/2 " type="text" placeholder="Your name" required/>
        <br /><br />
        <input id="email" class="border border-[#4c4c4c] rounded-md pl-1 h-10 w-1/2 " type="text" placeholder="Your email" pattern="\w+@{1}(\w+\.{1}\w+)+" required/>
        <br /><br />
        <label for="message" class="hidden">Message:</label>
        <textarea id="message" class="w-full border border-[#4c4c4c] rounded-md pl-2 h-20" placeholder="Your message"></textarea>
        <br /><br />
        <button type="submit" class="bg-[#7a5eb0] hover:bg-[#9078bd]/80 active:bg-[#a692ca]/60 text-white font-bold w-[100px] h-[40px] rounded-full">Submit</button>
    </form>
</section>