import menu from './data/dishes.json'
import allergenIcons from "./data/food-icons.json"
import { MenuSection } from './MenuSection'

export const Menu = () => {
    const pathToIconsDir = "./icons/"

    const buildSection = (data) => {
        const sections = []
        for (let section in data) {
            sections.push(
            <li key={section}>
                <MenuSection section={section} dishes={data[section]}/>
            </li>)
        }
        return sections
    }

    return (
    <section className="sm:min-h-screen">
        <section className="grid grid-cols-1 w-screen mb-3">
            <section className="hidden sm:block"></section>
            <section className="max-w-full lg:w-full text-center">
                <h3 className="my-4 font-bold text-4xl mb-4 flex place-content-center">Menu</h3>
                <p className="italic text-xl mb-6 flex place-content-center">Oie's selection of delicious, hand-crafted Thai dishes, freshly made every day...</p>
                <p className="italic text-xl mb-6 flex place-content-center font-bold">
                    Mild 🌶️, Medium 🌶️🌶️, & Spicy 🌶️🌶️🌶️ options available
                </p>
                <div className="grid grid-rows-1 grid-cols-3 text-sm sm:justify-end lg:px-[250px]">
                    {allergenIcons.map(item => 
                    <span className='inline-flex place-content-center'>
                        <img className='h-5' src={require(`${pathToIconsDir + item.path}`)} alt={item.name} />
                        &nbsp; {item.description}
                    </span>  
                    )}
                </div>
                <br />
                <section className="text-center italic mx-2 font-bold text-2xl bg-[#E9D502] p-4 rounded border-dashed border-black border-2">
                    <p>PLEASE BE AWARE. All our dishes are prepared in a kitchen that uses peanuts. If you have a peanut allergy, DO NOT purchase our food</p>
                </section>
                <br />
                <ul>
                    {buildSection(menu)}
                </ul>
            </section>
            <section class="hidden sm:block"></section>
        </section>
        <br />

    </section>
)}