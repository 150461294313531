import './App.css';
import {Route, Routes } from "react-router-dom"
import { Home } from './components/Home.jsx';
import { Header } from './components/Header.jsx';
import { Menu } from './components/Menu.jsx';
import { About } from './components/About.jsx';
import { ContactUs } from './components/ContactUs.jsx';
import { Footer } from './components/Footer.jsx';
import { NotFound } from './components/NotFound';

function App() {
  const navigation = [
    {name: "Home", link: "/", component: <Home />},
    {name: "Menu", link: "/menu", component: <Menu/>},
    {name: "About Us", link: "/about", component: <About />},
    // {name: "Contact Us", link: "/contact-us", component: <ContactUs />}
  ]

  return (
    <section>
      <Header navBar={navigation}/>
      <Routes>
        {navigation.map(nav => <Route exact path={nav.link} element={nav.component} />)}
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <Footer navBar={navigation}/>
    </section>
  );
}

export default App;
