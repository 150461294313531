export function getSpice(heatLevel) {
    return "🌶️".repeat(heatLevel)
}

export function getWarningLabel(options) {
    let length = Object.keys(options).length
    let nuts = 0
    let vege = 0
    let vegan = 0
    let seafood = 0

    for (let dish in options) {
        let food = options[dish]

        if (food.containsNuts) nuts++
        if (food.isVegetarian) vege++
        if (food.isVegan) vegan++
        if (food.hasSeafood) seafood++
    }

    let nutWarning = nuts === length ? "contains nuts"
        : nuts === 0 ? "" : "may contain nuts"
    let seafoodWarning = seafood === length ? "contains seafood"
        : seafood === 0 ? "" : "may contain seafood"
    let vegeSuitable = vege === length ? "suitable for vegetarians"
        : nuts === 0 ? "" : "may be suitable for vegetarians"
    let veganSuitable = vegan === length ? "suitable for vegans"
        : nuts === 0 ? "" : "may be suitable for vegans"

    let delim = ", "
    let finalWarning = [nutWarning, seafoodWarning, vegeSuitable, veganSuitable]
        .filter(str => str !== "")
        .join(delim)
    return finalWarning.length === 0 ? "" 
        : finalWarning[0].toUpperCase() + finalWarning.substring(1)
}

export function getTags(obj) {
    return [obj.isVegetarian ? "./icons/vegetarian-icon.jpg" : "",
            obj.isVegan ? "./icons/vegan-icon.jpg" : "",
            obj.containsNuts ? "./icons/contains-nuts.png" : ""]
        .filter(tag => tag !== "")
}

export function listOptions(dish) {
    const arr = []
    for (let option in dish) {
        let obj = {
          name: option,
          ...dish[option]
        }
        arr.push(obj)
    }
  
    return arr;
  }